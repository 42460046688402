<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>系统设置</el-breadcrumb-item>
            <el-breadcrumb-item>数据管理</el-breadcrumb-item>
            <el-breadcrumb-item>数据导出</el-breadcrumb-item>
        </el-breadcrumb>
     <el-card shadow="hover" class="box-card" align="middle">
			 <div class="head">
        <i
          class="el-icon-s-tools"
          style="font-size: 18px;"
        ></i>
        <span class="text">设置选项</span>
      </div>
      </br></br></br>
      <el-form :model="dataFileSetting" label-width="150px" :rules="rules">
				<el-form-item label="批量删除：">
					<el-switch v-model="dataFileSetting.batchremove"></el-switch>
				</el-form-item>

				<el-form-item label="高级搜索：">
					<el-switch v-model="dataFileSetting.advancesearch"></el-switch>
				</el-form-item>
				<el-form-item label="单页展示数：" prop="numberperpage">
					<el-input-number v-model="dataFileSetting.numberperpage" :step="10" :min="10" :max="50">
					</el-input-number>
					<!-- <span class="note-margin">未收货，订单自动完成</span> -->
				</el-form-item>
				<el-form-item label="数据导出格式：">
					<!-- <span>数据导出格式：</span> -->
					<el-select v-model="dataFileSetting.format" placeholder="导出格式" style="width: 390px">
						<el-option v-for="item in formatoptions" :key="item.value" :label="item.label"
							:value="item.label">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button class="finish" type="primary" @click="onSubmit">完成</el-button>
				</el-form-item>

			</el-form>
      </el-card>

    </div>
</template>

<script>
// 验证规则，需为数字值
 const checkTime = (rule, value, callback) => {
    if (!value) {
      return callback(new Error('输入不能为空'));
    }
    console.log("checkTime",value);
    let intValue = parseInt(value);
    if (!Number.isInteger(intValue)) {
      return callback(new Error('请输入数字值'));
    }
    callback();
  };
    export default {
        name: "set_dataExport",
         data() {
      return {
      dataFileSetting:{
         	name: "数据导出",
					numberperpage: 0,
					batchremove: false,
					advancesearch: false,
          format:""

      },
      	formatoptions: [{
						value: "选项1",
						label: "DFB文件",
					},
					{
						value: "选项2",
						label: "CSV文件",
					},
					{
						value: "选项3",
						label: "EXCEL文件",
					},
                    {
						value: "选项4",
						label: "脚本文件",
					},
				],
        rules: {
          pageCount:{validator: checkTime, trigger: 'blur' }
        }
      }
    },
    	mounted() {
					this.initData();
				},
        methods:{
      // 完成按钮
       onSubmit() {
      this.$confirm("此操作将修改设置信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.dataFileSetting);
          this.putRequest(
            "/systemSetting/updateSettingInfoByName",
            this.dataFileSetting
          ).then((resp) => {
            // this.postRequest('/systemSetting/insertSettingInfo',this.dataFileSetting).then(resp => {
            if (resp) {
              console.log("修改成功!"); 
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    initData(){
            
                this.getRequest('/systemSetting/getSettingInfoByName/?name=' + this.dataFileSetting.name).then(resp=>{
                    if (resp){
                  
                        this.dataFileSetting.advancesearch = resp.data.advancesearch;
                        this.dataFileSetting.batchremove = resp.data.batchremove;
                        this.dataFileSetting.numberperpage = resp.data.numberperpage;
                      
                    }
                })
            }
  }
    }
    
</script>


<style scoped>
.el-card{
  width: 800px!important;
}
.head{
  float: left;
}
.box-card {
  width: 580px;
  margin:40px 170px;
  border: 1px solid #d5ecf6;
  font-size: 15px!important;
}
.box-card .text{
  display: inline-block;
  margin-left: 10px;

}
.el-form-item{
  margin-bottom: 30px;
  padding-left: 50px;
}
.finish {
  margin-top: 30px;
  margin-left: 330px;
}
</style>